@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
  .personalDataWrapper {
    @include media-breakpoint-down(md) {
      font-size: $font-size-sm;
    }
    > p {
      margin-bottom: 0;
    }
  }
  .bgTransparent {
    background-color: $card-bg-color;
  }
}
