@import 'src/styles/_variables';

.wrapper {
  .pricingTable {
    text-align: center;
    background: $white;
    margin: 0 -15px;
    box-shadow: 0 0 10px $gray-500;
    padding-bottom: 7px;
    border-radius: 10px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    margin: 1rem;
    cursor: pointer;
    &.active {
      z-index: 1;
      .priceValue {
        color: $white;
      }
      .pricingTableHeader {
        background: $primary;
      }
    }
    &:hover {
      transform: scale(1.05);
      z-index: 1;
      .priceValue {
        color: $white;
      }
      .pricingTableHeader {
        background: $primary;
      }
    }
    .pricingTableHeader {
      padding: 1.5rem 0;
      background: $gray-300;
      border-radius: 10px 10px 50% 50%;
      transition: all 0.5s ease 0s;
    }
    .textPrice {
      color: $black;
      font-size: 1.5rem;
      margin-left: 10px;
      font-weight: lighter;
    }
    .priceValue {
      font-size: 2rem;
      color: $primary;
      transition: all 0.5s ease 0s;
      display: flex;
      align-items: center;
      align-content: center;
      margin-left: 20px;
      font-weight: bolder;
    }
    .prices {
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: end;
      margin-right: 1.5rem;
      font-weight: bold;
      .pricePerUnit {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
      .totalPrice {
        font-size: 2rem;
      }
    }
  }
}
