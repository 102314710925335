@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.greenBackground {
  background-color: $olive-green;
}

.orderTable {
  td {
    vertical-align: middle;
  }
}

.textForAnalyse {
  display: inline-block;
  min-width: 40px;
}
