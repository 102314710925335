@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.greenBackground {
  background-color: $olive-green;
}

.summary {
  background-color: $white;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}

.noBorder > td {
  border-style: none;
}

.verticalAlignment {
  vertical-align: middle;
}

.deleteButton {
  color: $gray-600;
  padding: 0;
  width: 55px;
  height: 55px;
  border-radius: 7px;
  background-color: $general-bg-color;
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.deleteButton:hover {
  color: $white;
  background-color: $primary;
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.topShadow {
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}
