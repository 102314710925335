@import 'src/styles/_variables';

.summary {
  background-color: $white;
}

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.wrapper-transparent {
  background-color: transparent;
  font-size: $font-size-base;
}

.noBorder > td {
  border-style: none;
}

.verticalAlignment {
  vertical-align: middle;
}

.deleteButton {
  color: $gray-600;
  padding: 0;
  width: 55px;
  height: 55px;
  border-radius: 7px;
  background-color: $general-bg-color;
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.deleteButton:hover {
  color: $white;
  background-color: $primary;
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rowTable {
  &:hover {
    background-color: #f5f5f5;
  }
}
