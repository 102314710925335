@import 'src/styles/_variables';

.bgTransparent {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.tile {
  color: $black;
  //$general-bg-color
}

.subTitle {
  color: $black;
}

.subTitle {
  color: $black;
}
