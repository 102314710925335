@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.rowTable {
  &:hover {
    background-color: #f5f5f5;
  }
}
