@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import 'src/styles/_variables';

.personal-wrapper {
  .nav-tabs {
    border: none;

    .nav-item {
      .nav-link {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: $white;
        background-color: #b68f64;
        font-size: $font-size-sm;
        border: none;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          padding: 0.35rem 0.45rem;
        }
        &.active {
          color: #111;
          background-color: $card-bg-color;
        }
      }
    }
  }
}
