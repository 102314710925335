@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.off-canvas-nav-bar {
  font-size: 1.5rem;
  &.active {
    background-color: #d99f36 !important;
  }
}
.nav-container {
  width: 100%;
  @include media-breakpoint-down(lg) {
    width: 80%;
  }
}
