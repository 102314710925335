@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
  .terms {
    background-color: $white;
    padding: 1rem;
    > p {
      margin-bottom: 5px;
    }
  }
}

.paypalImg {
  cursor: pointer;
  &.disabled {
    filter: grayscale(100%);
    cursor: auto;
  }
}
