@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.summary {
  background-color: $white;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}

.specieDetails {
  background-color: $olive-green;
}

.noticeBg {
  background-color: #dae9d1;
}

.accentLabel {
  color: $accent;
}

.topShadow {
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}

.bgYellowHover {
  &:hover {
    background-color: $general-bg-color;
  }
}
