@import 'src/styles/_variables';

.subTitle {
  color: $black;
  font-size: 1.4rem;
  font-weight: 500;
}

.lightSubTitle {
  color: $black;
  font-size: 1.2rem;
  font-weight: 200;
}
