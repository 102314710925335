@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.step {
  width: 70px;
  height: 70px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
