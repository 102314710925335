// Superhero 5.1.3
// Bootswatch

$theme: 'superhero' !default;

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #ebebeb !default;
$gray-200: #4e5d6c !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #908e8e !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #4c9be8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #eea236 !default;
$yellow: #ffc107 !default;
$green: #5cb85c !default;
$teal: #20c997 !default;
$cyan: #5bc0de !default;
$olive-green: #bfdcd3 !default;
$accent: #cdb394 !default;

$primary: $orange !default;
$secondary: $gray-200 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: lighten($gray-200, 35%) !default;
$dark: #20374c !default;

$min-contrast-ratio: 1.6 !default;

$general-bg-color: #f3ede2 !default;
$card-bg-color: rgba(243, 237, 226, 0.87);

// Body

$body-bg: #0f2537 !default;
$body-color: $gray-100 !default;

// Components

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$text-muted: rgba(255, 255, 255, 0.4) !default;

// Tables

$table-accent-bg: rgba($white, 0.05) !default;
$table-hover-bg: rgba($white, 0.075) !default;
$table-border-color: rgba($black, 0.15) !default;
$table-head-bg: $light !default;
$table-dark-bg: $light !default;
$table-dark-border-color: $gray-200 !default;
$table-dark-color: $body-bg !default;

$table-bg-scale: 0 !default;

// Forms

$input-bg: $white !default;
$input-disabled-bg: $gray-100 !default;

$input-color: $gray-900 !default;
$input-border-color: transparent !default;
$input-border-width: 0 !default;

$input-placeholder-color: $gray-600 !default;

$input-group-addon-color: $body-color !default;

$form-check-input-bg: $white !default;
$form-check-input-border: none !default;

$form-file-button-color: $body-color !default;

$form-floating-label-opacity: 1 !default;

// Dropdowns

$dropdown-bg: $gray-200 !default;
$dropdown-divider-bg: rgba($black, 0.15) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: $dropdown-link-color !default;
$dropdown-link-hover-bg: $table-hover-bg !default;

// Navs

$nav-link-disabled-color: rgba(255, 255, 255, 0.4) !default;
$nav-tabs-border-color: $gray-200 !default;
$nav-tabs-link-active-color: $body-color !default;
$nav-tabs-link-active-border-color: $gray-200 !default;

// Navbar

$navbar-dark-color: rgba($white, 0.75) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: #333333;
$navbar-light-active-color: #f3ede2;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $gray-200 !default;
$pagination-border-color: transparent !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: $nav-link-disabled-color !default;
$pagination-hover-border-color: $pagination-border-color !default;
$pagination-disabled-color: $nav-link-disabled-color !default;
$pagination-disabled-bg: $pagination-bg !default;
$pagination-disabled-border-color: $pagination-border-color !default;

// Cards

$card-cap-bg: $table-hover-bg !default;
$card-bg: $gray-200 !default;
$card-inner-border-radius: 0 !default;

// Popovers

$popover-bg: $gray-200 !default;
$popover-header-bg: $table-hover-bg !default;

// Toasts

$toast-background-color: $gray-200 !default;
$toast-border-color: rgba(0, 0, 0, 0.2) !default;
$toast-header-color: $body-color !default;
$toast-header-background-color: $toast-background-color !default;
$toast-header-border-color: $toast-border-color !default;

// Modals

/*$modal-content-bg: $gray-200 !default;
$modal-header-border-color: rgba(0, 0, 0, 0.2) !default;*/

// List group

$list-group-color: $white !default;
$list-group-bg: $gray-200 !default;
$list-group-border-color: transparent !default;
$list-group-hover-bg: $nav-link-disabled-color !default;
$list-group-disabled-color: $nav-link-disabled-color !default;
$list-group-action-color: $white !default;
$list-group-action-hover-color: $white !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-bg: $gray-200 !default;
$breadcrumb-divider-color: $body-color !default;
$breadcrumb-active-color: $body-color !default;

// Close

$btn-close-color: $white !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 1 !default;

// Code

$pre-color: inherit !default;

// Off Canvas

$offcanvas-bg-color: $general-bg-color;
$btn-close-color: $black;
