@import 'src/styles/_variables';

.wrapper {
  background-color: $card-bg-color;
  font-size: $font-size-base;
}

.greenBackground {
  background-color: $olive-green;
}

.orderTable {
  td {
    vertical-align: middle;
  }
}

.topShadow {
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}
