// Superhero 5.1.3
// Bootswatch

// Variables

$web-font-path: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap' !default;
@if $web-font-path {
  @import url($web-font-path);
}

html {
  scroll-behavior: smooth;
}

// Buttons

.btn {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0.5rem 1.4rem;
      font-size: 0.9rem;
      @if $enable-gradients {
        background: $value
          linear-gradient(180deg, mix($white, $value, 15%), $value)
          repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

// Typography

.dropdown-menu {
  font-size: $font-size-sm;
}

.dropdown-header {
  font-size: $font-size-sm;
}

.blockquote-footer {
  color: $body-color;
}

// Tables

.table {
  font-size: $font-size-sm;

  .thead-dark th {
    color: $white;
  }

  a:not(.btn) {
    color: $white;
    text-decoration: underline;
  }

  .dropdown-menu a {
    text-decoration: none;
  }

  .text-muted {
    color: $text-muted;
  }
}

// Forms

label,
.radio label,
.checkbox label,
.help-block {
  font-size: $font-size-base;
}

.form-select,
.form-label,
.form-control {
  font-size: $font-size-base;
}

.form-floating {
  label {
    color: $input-placeholder-color;
  }
}

// Navs

.bg-light {
  background-color: rgba(243, 237, 226, 0.47) !important;
  backdrop-filter: blur(4px);
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;
    }

    .show > .nav-link,
    .nav-link.active {
      background-color: $navbar-light-active-color;
      color: $navbar-light-color;
    }
  }
  .navbar-text {
    color: $navbar-light-color;

    a,
    a:hover,
    a:focus {
      color: $navbar-light-color;
    }
  }
}

.page-link:hover,
.page-link:focus {
  color: $white;
  text-decoration: none;
}

// Indicators

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value
          linear-gradient(180deg, mix($white, $value, 15%), $value)
          repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

.badge {
  &-warning,
  &-info {
    color: $white;
  }
}

// Popovers

.popover-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// Containers

/*
.modal {
	&-header,
	&-footer {
		background-color: $table-hover-bg;
	}
}
*/
